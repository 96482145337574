import "./Footer.scss";

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-copyright">
        &copy; 2024 Copyright: Tech Haven. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
